import { format } from "date-fns";
import { useGeneralStore } from "~/stores/general";
import { useCheckoutStore } from "~/stores/checkout";
import {
  CartItem,
  Rate,
  DiscountOptionItem,
  GiftCardOptionItem,
} from "~/types/api/cart.types";
// import { ShippingMethodsRate } from "@/types/api/orders.types";
import { DataURIToBlob, parseJson } from "@/utils";
import { discountConfig } from "~/configs";

export const useCart = () => {
  const { $api, $i18n } = useNuxtApp();

  const { cartUniqueId } = useCookiesService();

  const { t } = useI18n();
  const generalStore = useGeneralStore();
  const checkoutStore = useCheckoutStore();

  const cartInfo = computed(() => {
    return generalStore.cart;
  });

  const cartItems = computed<CartItem[]>(() => {
    return cartInfo.value?.items || [];
  });

  const cartItemsQuantity = computed<number>(() => {
    return (
      cartInfo.value?.items.reduce((total, item) => total + item.quantity, 0) ||
      0
    );
  });

  const cartItemsGrouped = computed<CartItem[]>(() => {
    type Arr = [string, CartItem];
    const arr = parseJson<CartItem[]>(JSON.stringify(cartItems.value)) || [];
    const keys = arr.reduce((acc, item) => {
      if (item?.options?.engagement?.product_type === "product") {
        acc.push([item.options.engagement.uid, item]);
      }
      return acc;
    }, [] as Arr[]);
    const arrMap = new Map(keys);
    const tree: any[] = [];

    arr.forEach((item) => {
      if (item?.options?.engagement?.product_type === "diamond") {
        const parentItem = arrMap.get(item.options.engagement.uid);

        if (parentItem) {
          if (parentItem.diamond) {
            parentItem.diamond.push(item);
          } else {
            parentItem.diamond = [item];
          }
        }
      } else {
        tree.push(item);
      }
    });
    return tree;
  });

  const shippingRate = computed<Rate | null>(() => {
    if (
      checkoutStore.firstStepShippingMethod &&
      checkoutStore.firstStepShippingMethods?.length
    ) {
      return (
        checkoutStore.firstStepShippingMethods?.find(
          (i) =>
            i.deliveryServiceCode === checkoutStore.firstStepShippingMethod,
        ) || null
      );
    }
    return (
      cartInfo.value?.shipping_rates?.rates?.find?.(
        (i) => i.deliveryServiceCode === cartInfo.value?.shipping_method,
      ) || null
    );
  });

  const estimatedDelivery = computed(() => {
    if (!shippingRate.value) return null;
    return generalStore.cart?.ship_date
      ? format(new Date(generalStore.cart?.ship_date), "dd LLLL")
      : t("shippingDateNotAvailable");
  });

  const shippingPrice = computed(() => Number(shippingRate.value?.price || 0));
  const currentPriceWithoutShipping = computed(() => {
    if (
      checkoutStore.firstStepShippingMethod &&
      checkoutStore.firstStepShippingMethods?.length
    ) {
      return (
        Number(cartInfo?.value?.base_amount) -
        Number(discountValueSum.value || 0)
      );
    }
    return Number(cartInfo?.value?.final_amount) - shippingPrice.value;
  });

  const currentPrice = computed(() => {
    const sum = currentPriceWithoutShipping.value + shippingPrice.value;
    return sum < 0 ? 0 : sum;
  });

  const giftCard = computed(() => {
    return cartInfo.value?.gift_card || null;
  });

  const discountCard = computed(() => {
    const discountCard = cartInfo.value?.discount;
    return discountCard
      ? {
          ...discountCard,
        }
      : null;
  });

  const discountSource = computed(() => {
    return giftCard.value || discountCard.value;
  });

  const discountValueSum = computed(() => {
    if (!discountSource.value) return null;
    const isProductDiscount = cartInfo.value?.items?.some((el) => el.discount);
    switch (discountSource.value.amount_type) {
      case discountConfig.type.static:
        return Number(
          discountSource.value?.discounted || discountSource.value?.amount || 0,
        );
      case discountConfig.type.percent:
        return Number(
          // discountSource.value.discounted || discountSource.value.amount
          checkoutStore.firstStepShippingMethod &&
            checkoutStore.firstStepShippingMethods &&
            !isProductDiscount
            ? (((cartInfo.value?.base_amount || 0) + shippingPrice.value) *
                discountSource.value.amount) /
                100
            : discountSource.value.discounted,
        );
      default:
        return 0;
    }
  });

  const discountValue = computed(() => {
    if (!discountSource.value) return null;
    switch (discountSource.value.amount_type) {
      case discountConfig.type.static:
        return `-${$i18n.n(discountValueSum.value as number, "currency")}`;
      case discountConfig.type.percent:
        return `-${$i18n.n(discountValueSum.value as number, "currency")}(${
          discountSource.value.amount
        }%)`;
      default:
        return null;
    }
  });

  const discountItems = computed(() => {
    if (cartInfo.value?.options?.discounts) {
      return Object.values(cartInfo.value.options.discounts);
    }
    return [];
  });

  const giftCardItems = computed(() => {
    if (cartInfo.value?.options?.gift_cards) {
      return Object.values(cartInfo.value.options.gift_cards);
    }
    return [];
  });

  function getDiscountValue(item: DiscountOptionItem) {
    switch (item.discount_type) {
      case discountConfig.type.static:
        return `-${$i18n.n(item.used_discount_amount, "currency")}`;
      case discountConfig.type.percent:
        return `-${$i18n.n(
          item.used_discount_amount,
          "currency",
        )}(${item.base_discount_amount}%)`;
      default:
        return null;
    }
  }

  function getGiftCardValue(item: GiftCardOptionItem) {
    switch (item.gift_card_type) {
      case discountConfig.type.static:
        return `-${$i18n.n(item.used_gift_card_amount, "currency")}`;
      case discountConfig.type.percent:
        return `-${$i18n.n(
          item.used_gift_card_amount,
          "currency",
        )}(${item.base_gift_card_amount}%)`;
      default:
        return null;
    }
  }

  async function addToCart(product: any, openCardDrawer = true) {
    if (!cartUniqueId.value) return;
    const formData = new FormData();

    product.images.forEach((image: string) => {
      const blobImg = DataURIToBlob(image);
      if (image && blobImg) {
        formData.append("images[]", blobImg);
      }
    });
    const blobImg0 = DataURIToBlob(product.images[0]);
    if (product.images[0] && blobImg0) {
      formData.append("image", blobImg0);
    }
    if (product.images_ids?.length) {
      formData.append("image_id", product.images_ids[0]);
      product.images_ids.forEach((el: string) => {
        formData.append("images_ids[]", el);
      });
    }

    if (product.cost) {
      formData.append("cost", product.cost);
    }
    if (product.engraving_options) {
      formData.append("engraving_options", product.engraving_options);
    }

    if (product.options) {
      formData.append("options", JSON.stringify(product.options));
    }

    formData.append("cart_unique_id", cartUniqueId.value);
    formData.append("sku", product.configuration_id);
    formData.append("vendor_model", product.vendor_model);
    formData.append("quantity", "1");

    try {
      const { data } = await $api.cart.addToCart(formData);
      generalStore.setCart(data || null);
      checkoutStore.isCartOpen = openCardDrawer;
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }

  async function updateCartItem(item: CartItem) {
    if (!cartUniqueId.value) return;
    const params = {
      cart_unique_id: cartUniqueId.value,
      ...item,
    };
    try {
      const { data } = await $api.cart.updateCartItem(item.id, params);
      generalStore.setCart(data || null);
    } catch (error) {
      console.error(error);
    }
  }
  async function removeCartItem(id: number, diamondId?: number) {
    if (!cartUniqueId.value) return;
    checkoutStore.removingIdsLoading = diamondId ? [id, diamondId] : [id];
    const params = {
      cart_unique_id: cartUniqueId.value,
    };
    try {
      await $api.cart.deleteCartItem(id, params);
      if (diamondId) {
        await $api.cart.deleteCartItem(diamondId, params);
      }
      const { data } = await $api.cart.getCart(
        generalStore.userInfo,
        cartUniqueId.value,
      );
      generalStore.setCart(data || null);
    } catch (error) {
      console.error(error);
    } finally {
      checkoutStore.removingIdsLoading = [];
    }
  }
  function closeCart() {
    checkoutStore.isCartOpen = false;
  }
  return {
    closeCart,
    cartInfo,
    cartItems,
    removeCartItem,
    updateCartItem,
    addToCart,
    giftCard,
    discountCard,
    discountSource,
    discountValue,
    cartItemsGrouped,
    shippingRate,
    estimatedDelivery,
    shippingPrice,
    currentPrice,
    currentPriceWithoutShipping,
    discountItems,
    giftCardItems,
    getDiscountValue,
    getGiftCardValue,
    cartUniqueId,
    cartItemsQuantity,
  };
};
